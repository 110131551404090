import { memo, useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useResizeDetector } from 'react-resize-detector'

import { Box, Divider, Link, Typography } from '@mui/material'
import { ControlledCheckbox, Spinner } from '@rent/ui'
import { DownloadFileButton } from 'components/shared'
import { ExternalLinks } from 'consts'
import { NotificationsContext, OrderContext, notifyError } from 'context'
import { ContractGeneratedDataFields, IAgreementResponse } from 'models'

import { contractContainerBoxStyle, contractContainerTitleBoxStyle } from './styles'

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

const maxDocumentWidth = 650

interface ContractViewProps extends IAgreementResponse {
  onLoadError: () => void
}

const ContractView = memo(({ frameworkAgreementExposedId, leaseAgreementExposedId, pdf, onLoadError }: ContractViewProps) => {
  const base64Pdf = `data:application/pdf;base64,${pdf}`
  const { formatMessage } = useIntl()
  const [numPages, setNumPages] = useState<number>()
  const { width: containerWidth, ref } = useResizeDetector()
  const { dispatchNotification } = useContext(NotificationsContext)
  const { contextData } = useContext(OrderContext)

  const { control } = useFormContext()

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    // scroll to the document position
    if (ref?.current) {
      window.scroll(0, ref?.current.offsetTop - 180)
    } else {
      window.scroll(0, 0)
    }
    setNumPages(numPages)
  }

  const onDownloadAgreement = () => {
    const linkSource = base64Pdf
    const downloadLink = document.createElement('a')
    const fileName = 'umowaWeSub.pdf'

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }

  const onDocumentLoadError = () => {
    notifyError(dispatchNotification, {
      title: formatMessage({ id: 'notification.dear_customer' }),
      content: formatMessage({ id: 'notification.pdf_loading_error' }),
    })
    onLoadError()
  }

  return (
    <Box>
      <Box sx={contractContainerBoxStyle}>
        <Box sx={contractContainerTitleBoxStyle}>
          <Typography variant="h5" mb={{ xs: 1, sm: 0 }}>
            {formatMessage({ id: 'notification.read_the_agreement' })}
          </Typography>
          <DownloadFileButton text="button.download_agreement" onClick={onDownloadAgreement} id="downloadAgreement-button" />
        </Box>
        <Divider />
        <Box
          sx={{
            height: 472,
            overflow: 'auto',
            scrollbarWidth: 'thin',
            my: 2.4,
          }}
          ref={ref}
        >
          <Document
            file={base64Pdf}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            loading={
              <Box sx={{ height: 472, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spinner />
              </Box>
            }
            error={<Typography sx={{ p: 1.6 }}>{formatMessage({ id: 'notification.pdf_loading_error' })}</Typography>}
          >
            {Array.from(Array(numPages).keys()).map(el => (
              <Page key={el + 1} pageNumber={el + 1} width={containerWidth ? containerWidth - 10 : maxDocumentWidth} />
            ))}
          </Document>
        </Box>
      </Box>
      <Box mt={1.6} mb={{ xs: 3.2, md: 6 }}>
        <ControlledCheckbox
          fieldName={ContractGeneratedDataFields.agreementAcceptance}
          label={formatMessage({ id: contextData.isLoan ? 'form.agreementAcceptanceLoan_label' : 'form.agreementAcceptance_label' })}
          required
          control={control}
          type="checkbox-accordion"
          labelExpanded={formatMessage(
            { id: contextData.isLoan ? 'form.agreementAcceptanceLoan_label_expanded' : 'form.agreementAcceptance_label_expanded' },
            {
              frameworkAgreementExposedId,
              leaseAgreementExposedId,
              art: (
                <>
                  77<sup>2</sup>
                </>
              ),
              link1: (
                <Link target="_blank" href={ExternalLinks.DOCUMENTS} rel="noreferrer" color="text.primary" id="wesubDocuments-link">
                  {formatMessage({ id: 'form.of_terms_and_toip' })}
                </Link>
              ),
            },
          )}
        />
      </Box>
    </Box>
  )
})

export default ContractView
