import { useContext, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, UseFormSetValue, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Button, InfoBar, LoadingDialog, StepTitle } from '@rent/ui'
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/ArrowForward.svg'
import { AxiosResponse } from 'axios'
import { StepContentLayout } from 'components/layouts'
import { NotReadyComponent, OrderSummaryPanel } from 'components/shared'
import { GeneralRoute, OrderStatus, RouteQueryParam, RouteQueryParamFinishedStatus } from 'consts'
import { BranchType } from 'consts/branchType'
import { NotificationsContext, notifyClose, notifyLoading } from 'context'
import { mapProducts } from 'helpers'
import { useWeBookExtension } from 'hooks'
import { ContractGeneratedDataFields, ContractGenerationDataFields, IAgreementResponse, ICustomerOrder, IMappedProduct } from 'models'

import { prepareGenerateOrderContractData } from '../api'
import { useOrderData, useVendorData } from '../hooks'
import CodeVerificationContainer from './components/CodeVerificationContainer'
import ContractView from './components/ContractView'
import FormData from './components/FormData'
import { ContractGenerationValidationSchema, formInitValues, validationSchema } from './consts'
import { useAcceptOffer, useCheckItemsAvailable, useCodeGeneration, useContractGeneration, usePrepareSecurityDetails } from './hooks'

const ContractGeneration = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const { dispatchNotification } = useContext(NotificationsContext)

  // get order data
  const {
    data: orderData,
    isLoading,
    isSuccess,
    isError,
    error,
    refetchOrderData,
  } = useOrderData({ status: [OrderStatus.OFFER, OrderStatus.OFFER_ACCEPTED] })
  // get vendor data and set logo
  useVendorData({ vendorId: orderData?.vendorId })

  const { allItemsAvailable } = useCheckItemsAvailable({ items: orderData?.items, isLoading })

  const externalId = orderData?.externalId ?? ''
  const isLoan = orderData?.branch === BranchType.CREDIT

  const { postAcceptOffer } = useAcceptOffer({ externalId, refetchOrderData })
  const { prepareSecurityDetails } = usePrepareSecurityDetails({ externalId })
  const { codeVerificationOpen, setCodeVerificationOpen, generateVerificationCode } = useCodeGeneration({ externalId })

  const { customerMaskedData, customerMaskedDataIsLoading, webookExtensionEnabledToken } = useWeBookExtension({
    status: orderData?.status,
    nip: orderData?.nip,
    isExtension: orderData?.extension,
  })

  const [orderProductsData, setOrderProductsData] = useState<IMappedProduct[]>([])

  const [contractData, setContractData] = useState<IAgreementResponse | null>(null)

  const initFormData = formInitValues({ buyerPhone: orderData?.buyerPhone ?? '' })

  const methods = useForm<ContractGenerationValidationSchema>({
    defaultValues: initFormData,
    resolver: zodResolver(validationSchema(formatMessage)),
    mode: 'onSubmit',
  })
  const {
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors },
  } = methods

  useEffect(() => {
    if (allItemsAvailable) {
      if (orderData?.status === OrderStatus.OFFER) {
        notifyLoading(dispatchNotification)
        postAcceptOffer.mutate()
      }
      if (orderData?.items && orderProductsData.length === 0) {
        setOrderProductsData(mapProducts(orderData?.items, orderData?.b2cOnline))
      }
      // might be null which we don't want to check here
    } else if (allItemsAvailable === false) {
      navigate({
        pathname: `/${GeneralRoute.ORDER}/${orderData?.externalId}/${GeneralRoute.ORDER_FINISHED_CONFIRMATION}`,
        search: `?${RouteQueryParam.ORDER_FINISHED_STATUS}=${RouteQueryParamFinishedStatus.PRODUCTS_NOT_AVAILABLE}`,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItemsAvailable, orderData?.b2cOnline, orderData?.items, orderData?.status])

  useEffect(() => {
    if (orderData?.externalId && allItemsAvailable && orderData?.status === OrderStatus.OFFER_ACCEPTED) {
      prepareSecurityDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.externalId, allItemsAvailable, orderData?.status])

  useEffect(() => {
    if (orderData?.status === OrderStatus.OFFER_ACCEPTED && allItemsAvailable) {
      prefillFormData(orderData, setValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData, allItemsAvailable, setValue])

  const mapRepresentantNames = orderData?.representantNames?.map(el => ({ value: el })) ?? []

  const handleSuccessGenerateOrderContract = (data: AxiosResponse<IAgreementResponse>) => {
    notifyClose(dispatchNotification)
    setContractData(data?.data)
    setValue(ContractGeneratedDataFields.agreementGenerated, true)
  }

  const { postGenerateOrderContract, onSubmitAcceptContract, contractAcceptanceLoading } = useContractGeneration({
    orderData,
    formData: methods.getValues(),
    handleSuccessGenerateOrderContract,
    webookExtensionEnabledToken,
  })

  const onSubmit: SubmitHandler<ContractGenerationValidationSchema> = async formData => {
    // contract acceptance
    if (contractData && formData.agreementAcceptance && !codeVerificationOpen) {
      await generateVerificationCode(formData.buyerPhone)
    } else if (contractData && codeVerificationOpen) {
      setCodeVerificationOpen(false)
      notifyLoading(dispatchNotification)
      onSubmitAcceptContract()
    }
    // contract generation
    else {
      notifyLoading(dispatchNotification, {
        title: formatMessage({ id: 'notification.contract_generation_loading' }),
        content: formatMessage({ id: 'notification.please_wait_several_sec' }),
      })
      const body = prepareGenerateOrderContractData(formData, orderData)
      await postGenerateOrderContract.mutate(body)
    }
  }

  const onEditData = () => {
    setContractData(null)
    setValue(ContractGeneratedDataFields.agreementGenerated, false)
    setValue(ContractGeneratedDataFields.agreementAcceptance, false)
  }

  const handleChangePhoneNo = () => {
    setCodeVerificationOpen(false)
    setTimeout(() => {
      setFocus(ContractGenerationDataFields.buyerPhone)
    }, 50)
  }

  const buyerPhone = methods.getValues(ContractGenerationDataFields.buyerPhone)

  const shouldDisplayContent = orderData?.status === OrderStatus.OFFER_ACCEPTED && allItemsAvailable

  return (
    <Box>
      <NotReadyComponent
        isLoading={isLoading || allItemsAvailable === null || customerMaskedDataIsLoading}
        isSuccess={isSuccess}
        isError={isError}
        error={error}
        redirect
      >
        {shouldDisplayContent && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <StepContentLayout rightSide={<OrderSummaryPanel items={orderProductsData} />}>
                <>
                  {/* title */}
                  <StepTitle
                    title={`3. ${formatMessage({ id: 'contract_generation' })}`}
                    subtitle={formatMessage(
                      {
                        id: orderData?.extension
                          ? 'message.penultimate_step_before_your_rent_extension'
                          : isLoan
                            ? 'message.penultimate_step_before_your_loan'
                            : 'message.penultimate_step_before_your_rent',
                      },
                      { number_of_steps: 3 },
                    )}
                  >
                    {orderData?.extension && customerMaskedData && (
                      <Box>
                        <InfoBar title={formatMessage({ id: 'message.extension_data_automatically_filled' })} align="left" iconSize={1.6} />
                      </Box>
                    )}
                  </StepTitle>

                  {/* form data */}
                  {orderData && (
                    <FormData
                      onSubmitForm={onSubmit}
                      representantNamesOptions={mapRepresentantNames}
                      disabled={Boolean(contractData)}
                      onEditData={onEditData}
                      externalId={externalId}
                      orderProductsData={orderProductsData}
                      buyerName={orderData.buyerName}
                      customerMaskedData={customerMaskedData}
                    />
                  )}

                  {contractData && <ContractView {...contractData} onLoadError={onEditData} />}
                  {codeVerificationOpen && (
                    <CodeVerificationContainer
                      onChangePhoneNo={handleChangePhoneNo}
                      onResendCode={() => generateVerificationCode(buyerPhone)}
                      onSubmitForm={onSubmit}
                      buyerPhone={buyerPhone}
                    />
                  )}
                  {contractData ? (
                    <Button
                      fullWidth
                      type={codeVerificationOpen ? undefined : 'submit'}
                      id="goToFinalization-button"
                      endIcon={<ArrowForwardIcon />}
                      disabled={Boolean(errors[ContractGenerationDataFields.buyerPhone])}
                    >
                      {formatMessage({ id: 'button.sign_agreement' })}
                    </Button>
                  ) : (
                    <Button fullWidth type="submit" id="generateAgreement-button">
                      {formatMessage({ id: 'button.generate_contract' })}
                    </Button>
                  )}
                </>
              </StepContentLayout>
            </form>
          </FormProvider>
        )}
        {contractAcceptanceLoading?.timerMinutes && (
          <LoadingDialog
            open
            timerMinutes={contractAcceptanceLoading.timerMinutes}
            timeIsOver={contractAcceptanceLoading.timeIsOver}
            title={formatMessage({ id: 'notification.agreement_signed_title' })}
            subtitle={formatMessage({ id: 'notification.data_verification' })}
            infoBar={
              contractAcceptanceLoading?.longWaiting
                ? { title: formatMessage({ id: 'message.data_loading_takes_longer_than_usual' }) }
                : null
            }
          >
            <Typography color="text.secondary" textAlign="center">
              {formatMessage({ id: 'payment.you_will_be_redirected_to_the_payment_screen' })}
            </Typography>
          </LoadingDialog>
        )}
      </NotReadyComponent>
    </Box>
  )
}

export const prefillFormData = (data: ICustomerOrder, setValue: UseFormSetValue<any>) => {
  setValue(ContractGenerationDataFields.nameAndLastName, data.representantNames ? data.representantNames[0] : '')
  setValue(ContractGenerationDataFields.buyerPhone, data.buyerPhone ?? '')
}

export default ContractGeneration
