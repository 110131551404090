interface ConsultantData {
  name: string
  phoneCode: string
  phoneNo: string
  email: string
}

export const ConsultantData: Readonly<ConsultantData> = {
  name: 'Dariusz Oleś',
  phoneCode: '+48',
  phoneNo: '459599677',
  email: 'biuro@wesub.pl',
}
