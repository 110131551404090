"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/hooks/index.ts
var hooks_exports = {};
__export(hooks_exports, {
  useCountdownTimer: () => useCountdownTimer
});
module.exports = __toCommonJS(hooks_exports);

// src/hooks/useCountdownTimer.tsx
var import_react = require("react");
var useCountdownTimer = ({ minutes, seconds = 0, timerValue, timeIsOver, hideSecZeros, callbackFn }) => {
  const [remainingTime, setRemainingTime] = (0, import_react.useState)(timerValue ?? minutes * 60 + seconds);
  const remainingMinutes = Math.floor(remainingTime / 60);
  const remainingSeconds = remainingTime % 60;
  (0, import_react.useEffect)(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => prevTime > 0 ? prevTime - 1 : 0);
    }, 1e3);
    if (timeIsOver) {
      setRemainingTime(0);
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [minutes, seconds, timeIsOver]);
  (0, import_react.useEffect)(() => {
    if (remainingTime === 0 && typeof callbackFn === "function") {
      callbackFn();
    }
  }, [remainingTime]);
  return {
    remainingTime,
    remainingMinutes: remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes,
    remainingSeconds: hideSecZeros ? remainingSeconds : remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
  };
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  useCountdownTimer
});
